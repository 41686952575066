<template>
  <OutsideCard title="REQUEST_ACCOUNT.TITLE" large>
    <v-form v-on:submit.prevent ref="form" v-model="formValidation" lazy-validation>
      <v-row>
        <v-col cols="12" class="mb-n3">
          <SalutationSelect v-model="formData.salutation" />
        </v-col>

        <v-col cols="6" class="mb-n3">
          <BaseTextField
            v-model="formData.firstName"
            label="REQUEST_ACCOUNT.FORM.LABELS.FIRST_NAME"
            required
            :errorMessages="hasErrors('firstName')"
          />
        </v-col>

        <v-col cols="6" class="mb-n3">
          <BaseTextField
            v-model="formData.lastName"
            label="REQUEST_ACCOUNT.FORM.LABELS.LAST_NAME"
            required
            :errorMessages="hasErrors('lastName')"
          />
        </v-col>

        <v-col cols="12" class="mb-n3">
          <BaseTextField
            v-model="formData.email"
            label="REQUEST_ACCOUNT.FORM.LABELS.MAIL"
            required
            mail
            :errorMessages="hasErrors('email')"
          />
        </v-col>

        <v-col cols="12" class="mb-n3">
          <BaseCheckbox
            v-model="isMember"
            label="REQUEST_ACCOUNT.FORM.LABELS.IS_MEMBER"
            class="mt-0 mb-6"
          />

          <BaseTextField
            :disabled="!isMember"
            v-model="formData.navisionNumber"
            label="REQUEST_ACCOUNT.FORM.LABELS.MEMBERSHIP_NUMBER"
            :rules="isMember && membershipNumberRules"
            :errorMessages="hasErrors('navisionNumber')"
          />
        </v-col>

        <v-col cols="12">
          <BaseHeadline size="4">{{ $t("REQUEST_ACCOUNT.TITLE_COMPANY") }}</BaseHeadline>
        </v-col>

        <v-col cols="12" class="mb-n3">
          <BaseTextField
            v-model="formData.companyName"
            label="REQUEST_ACCOUNT.FORM.LABELS.COMPANY.NAME"
            required
            :errorMessages="hasErrors('companyName')"
          />
        </v-col>

        <v-col cols="12" class="mb-n3">
          <BaseTextArea
            v-model="formData.companyAddress"
            label="REQUEST_ACCOUNT.FORM.LABELS.COMPANY.ADDRESS"
            required
            no-resize
            height="120"
            :errorMessages="hasErrors('companyAddress')"
          />
        </v-col>

        <v-col cols="12" class="mb-n3">
          <BaseCheckbox
            v-model="formData.checkConditions"
            required
            :errorMessages="hasErrors('checkConditions')"
          >
            <template #label>
              <span>
                {{ $t("REQUEST_ACCOUNT.FORM.LABELS.TERMS_AND_CONDITIONS.PRE") }}
                <a
                  :href="$t('REQUEST_ACCOUNT.FORM.LABELS.TERMS_AND_CONDITIONS.URL')"
                  target="_blank"
                  @click.stop
                >
                  {{ $t("REQUEST_ACCOUNT.FORM.LABELS.TERMS_AND_CONDITIONS.LABEL") }}
                </a>
                {{ $t("REQUEST_ACCOUNT.FORM.LABELS.TERMS_AND_CONDITIONS.POST") }}
              </span>
            </template>
          </BaseCheckbox>
        </v-col>

        <v-col cols="12" class="mb-n3">
          <BaseCheckbox
            v-model="formData.checkPrivacyPolicy"
            required
            :errorMessages="hasErrors('checkPrivacyPolicy')"
          >
            <template #label>
              <span>
                {{ $t("REQUEST_ACCOUNT.FORM.LABELS.DATA_AGREEMENT.PRE") }}
                <a
                  :href="$t('REQUEST_ACCOUNT.FORM.LABELS.DATA_AGREEMENT.URL')"
                  target="_blank"
                  @click.stop
                >
                  {{ $t("REQUEST_ACCOUNT.FORM.LABELS.DATA_AGREEMENT.LABEL") }}
                </a>
                {{ $t("REQUEST_ACCOUNT.FORM.LABELS.DATA_AGREEMENT.POST") }}
              </span>
            </template>
          </BaseCheckbox>
        </v-col>

        <v-col cols="12" class="mb-n3">
          <BaseCheckbox
            v-model="formData.checkDataProcessing"
            label="REQUEST_ACCOUNT.FORM.LABELS.DATA_PROCESSING_LABEL"
            required
            :errorMessages="hasErrors('checkDataProcessing')"
          />
        </v-col>

        <v-col cols="12" class="mt-5">
          <BaseText body="2" color="lowEmphasis">
            {{ `* ${$t("REQUEST_ACCOUNT.FORM.REQUIRED")}` }}
          </BaseText>
        </v-col>

        <v-col cols="12">
          <div class="text-right mt-1">
            <BaseButton @click="requestReset" :loading="isLoading" :disabled="isLoading">
              {{ $t("REQUEST_ACCOUNT.FORM.BTN_LABEL") }}
            </BaseButton>
          </div>
        </v-col>
      </v-row>

      <input type="hidden" v-model="specialValue" />
    </v-form>
    <template #cardFooter>
      <p class="gray800--text">
        {{ $t("REQUEST_ACCOUNT.LINK") }}
        <router-link :to="{ name: 'Login' }" class="text-decoration-none">
          {{ $t("REQUEST_ACCOUNT.LINK_LABEL") }}
        </router-link>
      </p>
    </template>
  </OutsideCard>
</template>

<script>
import { mapActions } from "vuex";
import formValidation from "@/mixins/formValidation";
import OutsideCard from "@components/Layout/OutsideCard.vue";
import SalutationSelect from "@components/FormElements/SalutationSelect.vue";
import RequestAccountData from "@/classes/RequestAccount";

export default {
  name: "RequestAccountForm",

  mixins: [formValidation],

  components: {
    OutsideCard,
    SalutationSelect,
  },

  data() {
    return {
      isLoading: false,
      specialValue: null,
      formData: null,
      formDataInstance: null,
      isMember: false,
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    initRequestForm() {
      this.formDataInstance = new RequestAccountData();
      this.formData = this.formDataInstance.init;
    },

    async requestReset() {
      this.validate();

      this.$nextTick(async () => {
        if (this.formValidation && !this.specialValue) {
          this.isLoading = true;

          try {
            await this.$axios.auth.requestAccount(this.formDataInstance.send(this.formData));
            this.$router.push({ name: "RequestAccountSuccess" });
          } catch (err) {
            console.error(err);
            this.setValidationErrors(err);
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
  },

  created() {
    this.initRequestForm();
  },
};
</script>
