export default class RequestAccountData {
  constructor() {
    this.requestFormData = {
      salutation: null,
      firstName: null,
      lastName: null,
      navisionNumber: null,
      companyName: null,
      companyAddress: null,
      checkConditions: null,
      checkDataProcessing: null,
      checkPrivacyPolicy: null,
    };
  }

  get init() {
    return this.requestFormData;
  }

  initWithData(requestFormData) {
    return requestFormData;
  }

  send(requestFormData) {
    return requestFormData;
  }
}
