<template>
  <BaseSelect
    v-model="salutation"
    :items="salutations"
    item-text="text"
    item-value="value"
    label="COMPONENTS.SALUTATION_SELECT.LABEL"
    filled
    v-on="$listeners"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: "SalutationSelect",

  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      salutation: this.value,
      salutations: [
        {
          text: this.$t("COMPONENTS.SALUTATION_SELECT.FEMALE"),
          value: "Frau",
        },
        {
          text: this.$t("COMPONENTS.SALUTATION_SELECT.MALE"),
          value: "Herr",
        },
        {
          text: this.$t("COMPONENTS.SALUTATION_SELECT.DIVERS"),
          value: "Hallo",
        },
      ],
    };
  },

  watch: {
    value(value) {
      this.salutation = value;
    },
    salutation(value) {
      this.$emit("input", value);
    },
  },
};
</script>
